export default function NetworkingNightContent() {
  return (
    <>
      <div className="bg-gray-100 py-10 px-6 sm:pt-12 sm:pb-6 lg:px-8 h-[70vh]">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Join us for Networking Night
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our first rush event takes place at 7:00pm on April 7th (Tech
            L211). You'll have the opportunity to meet many KTP members, learn
            more about our organization, and hopefully move on to the next
            round. The dress code is business casual.
          </p>
        </div>
      </div>
    </>
  );
}
